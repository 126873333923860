import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import i18n from 'i18n-js';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import { DishCreateModel } from 'api/models';
import menuDetailsStore from 'store/menuDetailsStore';
import { SectionData, DishData } from 'store/menuDetailsStore/reducer';
import { ApplicationState } from 'store';
import categoryStore from 'store/categoryStore';
import DishManagementDialog from './DishManagementDialog';
import { TaxType, SpicinessLevels, Diets, DietaryLaws } from 'constants/enums';

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export interface AddNewDishProps {
    sections: SectionData[];
    sectionId: string;
    close?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    forThreeDotsMenu: boolean;
    dailyMenuId: string;
}

export const AddNewDishItem = React.forwardRef((props: AddNewDishProps, ref?) => {
    const dispatch = useDispatch();

    const categoryDetails = useSelector(
        (state: ApplicationState) => state.category.categoryDetails
    );

    const [open, setOpen] = useState(false);
    const dishes = useRef<string[]>([]);

    const initialFormValues: DishData = {
        id: '',
        isDisabled: false,
        name: '',
        shortName: '',
        description: '',
        shortDescription: '',
        price: 0,
        photo: '',
        spicinessLevel: SpicinessLevels.None,
        diet: Diets.None,
        dietaryLaw: DietaryLaws.None,
        sectionId: props.sectionId,
        addToGallery: false,
        categoryId: '',
        allergens: [],
        ingredients: [],
        primeCost: 0,
        vorortTax: TaxType.None,
        homeDeliveryTax: TaxType.None,
        takeAwayTax: TaxType.None,
        sectionName: '',
        sections: [],
        dailyMenuId: props.dailyMenuId,
        barcode: '',
        stockQuantity: 0,
        minimumStock: 0,
        notFood: false,
        color: '',
        labels: [],
        size: '',
        additionalSizes: [],
    };

    function handleClickOpen(event?: React.MouseEvent<HTMLElement, MouseEvent>) {
        event?.stopPropagation();
        event?.preventDefault();
        setOpen(true);
        props.close && props.close(event);
    }

    function handleClose(event?: React.MouseEvent<HTMLElement, MouseEvent>) {
        event?.stopPropagation();
        event?.preventDefault();
        setOpen(false);
        props.close && props.close(event);
    }

    function createDish(model: DishCreateModel) {
        dispatch(menuDetailsStore.actionCreators.addNewItem(model));
    }

    useEffect(() => {
        if (!categoryDetails) {
            dispatch(categoryStore.actionCreators.getCategoryDetails());
        } else {
            dishes.current = categoryDetails!.dishes.map((x) => x.name.toUpperCase());
        }
    }, [categoryDetails]); // eslint-disable-line

    return (
        <>
            {props.forThreeDotsMenu ? (
                <StyledMenuItem color="primary" onClick={handleClickOpen}>
                    <AddIcon fontSize="small" color="disabled" style={{ marginRight: 23 }} />
                    <ListItemText primary={i18n.t('restaurant.createNewItem')} />
                </StyledMenuItem>
            ) : (
                <Button variant="contained" color="primary" onClick={handleClickOpen}>
                    {i18n.t('button.newItem')}
                </Button>
            )}
            <DishManagementDialog
                dish={initialFormValues}
                disabled={false}
                sections={props.sections}
                action={createDish}
                isOpen={open}
                onClose={handleClose}
            />
        </>
    );
});
