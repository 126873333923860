import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import i18n from 'i18n-js';
import { Form, Formik } from 'formik';
import * as FormikFields from 'formik-material-ui';
import { IconButton, TextField as MuiTextField } from '@material-ui/core';
import { Divider, ListItemText, Checkbox } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/Edit';
import * as yup from 'yup';

import { images } from 'assets';
import { regexHelper } from 'helpers/regexHelper';
import { BasicThreeDotsMenu, CustomPhoneInput, Input, ImageContainer } from 'components/shared';
import { helperFunctions } from 'helpers';
import { getDayList, getFormattedHour, getOrderPreparationDurations } from '../functions';
import {
    getDynamicRowLength,
    renderMultipleClassNames,
    truncateString,
} from 'helpers/helperFunctions';
import { ApplicationState } from 'store';
import { CuisineData } from 'store/restaurantDetailsStore/reducer';
import styles from '../styles';
import { theme } from 'config/theme';
import AddWorkingHoursModal from './AddWorkingHoursModal';
import TextEditor from 'components/shared/components/TextEditor';
import SetFieldText from 'components/shared/components/SetFieldText';

interface RestaurantDetailsProps {
    restaurantInformation: any;
    formikRef: React.Ref<any>;
    logoUploadRef: React.Ref<any>;
    photoUploadRef: React.Ref<any>;
    onFormChange: () => void;
}

export default function RestaurantDetails(props: RestaurantDetailsProps) {
    const cuisineTypes = useSelector(
        (state: ApplicationState) => state.restaurantDetails.cuisineTypes
    );
    const classes = styles();
    const restaurantOpenHoursRef: any = React.useRef(null);
    const [initialValues, setInitialValues] = useState({
        name: props.restaurantInformation.name,
        addressLine: props.restaurantInformation.addressLine,
        zip: props.restaurantInformation.zip,
        city: props.restaurantInformation.city,
        phoneNumber: props.restaurantInformation.phoneNumber,
        email: props.restaurantInformation.email,
        description: props.restaurantInformation.description,
        hasHomeDelivery: props.restaurantInformation.hasHomeDelivery,
        hasTakeAway: props.restaurantInformation.hasTakeAway,
        hasOnSite: props.restaurantInformation.hasOnSite,
        logoUrl: props.restaurantInformation.logoUrl,
        photoUrl: props.restaurantInformation.photoUrl,
        website: props.restaurantInformation.website,
        facebook: props.restaurantInformation.facebook,
        instagram: props.restaurantInformation.instagram,
        workdays: props.restaurantInformation.workdays || [],
        saturday: props.restaurantInformation.saturday || [],
        sunday: props.restaurantInformation.sunday || [],
        openHours: props.restaurantInformation.openHours,
        type: props.restaurantInformation.type || [],
        addToGallery: props.restaurantInformation.addToGallery || false,
        ordersDeadline: props.restaurantInformation.ordersDeadline || 30,
        longitude: props.restaurantInformation.longitude,
        latitude: props.restaurantInformation.latitude,
    });
    const [showWorkingHoursModal, setShowWorkingHoursModal] = useState(false);
    const [, setRestaurantLogo] = useState('');
    const [render, reRender] = useState(false);
    const [selectedOpenHoursDays, setSelectedOpenHoursDays] = useState<string[]>([]);
    const [showEditor, setShowEditor] = useState(false);
    const [textToEdit, setTextToEdit] = useState('');
    const [valueName, setValueName] = useState('');

    function saveTextFromEditor(text: string) {
        setTextToEdit(text);
        props.onFormChange();
    }

    const [width, setWidth] = useState(0);
    const divRef = useRef<HTMLDivElement>(null);

    function updateWidth() {
        if (divRef.current) {
            setWidth(divRef?.current?.offsetWidth);
        }
    }

    useEffect(() => {
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    console.log('divRef', divRef);

    useEffect(() => {
        initialValues.name !== props.restaurantInformation.name &&
            setInitialValues({
                name: props.restaurantInformation.name,
                addressLine: props.restaurantInformation.addressLine,
                zip: props.restaurantInformation.zip,
                city: props.restaurantInformation.city,
                phoneNumber: props.restaurantInformation.phoneNumber,
                email: props.restaurantInformation.email,
                description: props.restaurantInformation.description,
                hasHomeDelivery: props.restaurantInformation.hasHomeDelivery,
                hasTakeAway: props.restaurantInformation.hasTakeAway,
                hasOnSite: props.restaurantInformation.hasOnSite,
                logoUrl: props.restaurantInformation.logoUrl,
                photoUrl: props.restaurantInformation.photoUrl,
                website: props.restaurantInformation.website,
                facebook: props.restaurantInformation.facebook,
                instagram: props.restaurantInformation.instagram,
                workdays: props.restaurantInformation.workdays || [],
                saturday: props.restaurantInformation.saturday || [],
                sunday: props.restaurantInformation.sunday || [],
                openHours: props.restaurantInformation.openHours,
                type: props.restaurantInformation.type || [],
                addToGallery: props.restaurantInformation.addToGallery || false,
                ordersDeadline: props.restaurantInformation.ordersDeadline,
                longitude: props.restaurantInformation.longitude,
                latitude: props.restaurantInformation.latitude,
            });

        const strVer = JSON.stringify(props.restaurantInformation.openHours);
        if (strVer && restaurantOpenHoursRef.current === null) {
            restaurantOpenHoursRef.current = JSON.parse(strVer);
        }
    }, [props.restaurantInformation.name]); // eslint-disable-line

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .max(
                128,
                i18n.t('form.errors.maxLength', {
                    name: 'Name',
                    length: 128,
                })
            )
            .required(
                i18n.t('form.errors.required', {
                    name: 'Name',
                })
            ),
        addressLine: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Address line',
            })
        ),
        email: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Email',
                })
            )
            .email(i18n.t('form.errors.email')),
        phoneNumber: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Phone number',
                })
            )
            .matches(regexHelper.phoneNumber, i18n.t('form.errors.phoneNumber')),
        zip: yup
            .string()
            .max(10)
            .required(
                i18n.t('form.errors.required', {
                    name: i18n.t('paymentDetails.zip'),
                })
            ),
        city: yup.string().matches(regexHelper.city, i18n.t('form.errors.city')),
    });

    function displayWorkingHoursModal(typeName: string) {
        setShowWorkingHoursModal(true);
        // @ts-ignore
        let workingHours = initialValues[typeName];
        workingHours = workingHours.length === 0 ? [{ from: '', to: '' }] : workingHours;
        Object.keys(workingHours).forEach((workingHour: any) => {
            if (workingHours[workingHour].from.toString().length === 4) {
                workingHours[workingHour].from = '0' + workingHours[workingHour].from;
            }
            if (workingHours[workingHour].to.toString().length === 4) {
                workingHours[workingHour].to = '0' + workingHours[workingHour].to;
            }
        });
    }

    /**
     * Hides working hours modal
     * and before that cleans the empty hours to avoid validation issues for backend
     */
    function hideWorkingHoursModal(resetHours: boolean = true) {
        for (const [, value] of Object.entries(initialValues.openHours)) {
            //@ts-ignore
            value.workingHours = value.workingHours.filter((hourData) => hourData.from !== '');
        }
        resetHours &&
            setInitialValues({
                ...initialValues,
                openHours: JSON.parse(JSON.stringify(restaurantOpenHoursRef.current)),
            });
        setShowWorkingHoursModal(false);
    }

    function hasAnyService(values: any) {
        const { hasHomeDelivery, hasOnSite, hasTakeAway } = values;
        if (hasHomeDelivery || hasOnSite || hasTakeAway) {
            return true;
        }
        return false;
    }

    return (
        <div className={classes.container}>
            <AddWorkingHoursModal
                title={i18n.t('restaurant.editWorkingHours')}
                isVisible={showWorkingHoursModal}
                onClose={hideWorkingHoursModal}
                onSave={() => {
                    hideWorkingHoursModal(false);
                    props.onFormChange();
                }}
                onHourAdd={(index, hours) => {
                    selectedOpenHoursDays.forEach((day) => {
                        initialValues.openHours[day].workingHours[index] = hours;
                        initialValues.openHours[day].isClosed = false;
                        setInitialValues(initialValues);
                    });
                    reRender(!render);
                }}
                onHourRemove={(index) => {
                    if (selectedOpenHoursDays.length > 0) {
                        let newInitialValues: any = {};
                        selectedOpenHoursDays.forEach((day, i) => {
                            newInitialValues =
                                i === 0 ? { ...initialValues } : { ...newInitialValues };
                            newInitialValues.openHours =
                                i === 0
                                    ? { ...initialValues.openHours }
                                    : { ...newInitialValues.openHours };

                            newInitialValues.openHours[day] = {
                                workingHours: initialValues.openHours[day].workingHours.filter(
                                    (item: any, itemIndex: number) => itemIndex !== index
                                ),
                                isClosed: false,
                            };
                        });
                        setInitialValues(newInitialValues);
                    }
                }}
                onCloseChecked={(isChecked) => {
                    selectedOpenHoursDays.forEach((day) => {
                        initialValues.openHours[day].isClosed = isChecked;
                        setInitialValues(initialValues);
                    });
                    reRender(!render);
                }}
                openHours={initialValues.openHours}
                onSelectedDaysChange={(days, checked, hours) => {
                    if (checked) {
                        days.forEach((day) => {
                            initialValues.openHours[day].isClosed = checked;
                        });
                    } else {
                        days.forEach((day) => {
                            hours.forEach((hour, index) => {
                                initialValues.openHours[day].workingHours[index] = hour;
                                initialValues.openHours[day].isClosed = false;
                            });
                            if (initialValues.openHours[day].workingHours.length > hours.length) {
                                initialValues.openHours[day].workingHours = initialValues.openHours[
                                    day
                                ].workingHours.filter(
                                    (val: any, hIndex: number) => hIndex < hours.length
                                );
                            }
                        });
                    }
                    setSelectedOpenHoursDays(days);
                    setInitialValues(initialValues);
                }}
            />
            <Formik
                innerRef={props.formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async () => {}}
            >
                {({ values, setFieldValue, errors, setFieldError }) => (
                    <>
                        <div
                            key="first-div"
                            onChange={() => {
                                props.onFormChange();
                                !hasAnyService(values) && setFieldError('hasOnSite', 'required');
                            }}
                            className={classes.restaurantDetailsLeftContainer}
                        >
                            <div className={classes.flexRowContainer} key="image" ref={divRef}>
                                <ImageContainer
                                    ref={props.logoUploadRef}
                                    photo={values.logoUrl}
                                    onChange={(_selected, fileUrl) => {
                                        setRestaurantLogo(fileUrl);
                                        setFieldValue('logoUrl', fileUrl);
                                        props.onFormChange();
                                    }}
                                    styles={{ marginBottom: 0, width: '48%' }}
                                    text={i18n.t('restaurant.uploadLogo').toUpperCase()}
                                />
                                <ImageContainer
                                    ref={props.photoUploadRef}
                                    photo={values.photoUrl}
                                    onChange={(_selected, fileUrl) => {
                                        setRestaurantLogo(fileUrl);
                                        setFieldValue('photoUrl', fileUrl);
                                        props.onFormChange();
                                    }}
                                    styles={{ marginBottom: 0, width: '48%' }}
                                    text={i18n.t('restaurant.uploadRestaurantPhoto').toUpperCase()}
                                />
                            </div>

                            <Form
                                className={classes.formContainer}
                                onChange={props.onFormChange}
                                key="form-group"
                            >
                                <div className={classes.flexRowSpaceBetween}>
                                    <h6 className={classes.sectionTitle}>
                                        {i18n.t('restaurant.restaurantInformation').toUpperCase()}
                                    </h6>
                                    <div className={classes.makePhotoMainCheckbox}>
                                        <Input
                                            component={FormikFields.CheckboxWithLabel}
                                            variant="filled"
                                            checked={values.addToGallery}
                                            name={'addToGallery'}
                                            key="addToGallery"
                                            type="checkbox"
                                            Label={{ label: i18n.t('form.addPhotoToGallery') }}
                                            placeholder={i18n.t('form.addPhotoToGallery')}
                                            color="primary"
                                        />
                                    </div>
                                </div>
                                <div className={classes.flexRowContainer}>
                                    <Input
                                        error={errors.name}
                                        className={classes.formInput}
                                        placeholder={i18n.t('form.restaurantName')}
                                        name="name"
                                        key="name"
                                        type="text"
                                        label={i18n.t('form.restaurantName')}
                                        variant="filled"
                                    />
                                    <Input
                                        error={errors.addressLine}
                                        className={classes.formInput}
                                        placeholder={i18n.t('form.restaurantAddress')}
                                        name="addressLine"
                                        key="addressLine"
                                        type="text"
                                        label={i18n.t('form.restaurantAddress')}
                                        variant="filled"
                                    />
                                </div>
                                <div className={classes.flexRowContainer}>
                                    <CustomPhoneInput
                                        width={'44.5%'}
                                        placeholder={i18n.t('form.phoneNumber')}
                                        value={values.phoneNumber}
                                        onChange={(val: string) =>
                                            setFieldValue('phoneNumber', val)
                                        }
                                        // @ts-ignore
                                        invalid={errors.phoneNumber}
                                    />
                                    <div className={classes.halfFormInputContainer}>
                                        <Input
                                            className={classes.halfFormInput}
                                            placeholder={i18n.t('form.zip')}
                                            name="zip"
                                            key="zip"
                                            type="text"
                                            label={i18n.t('form.zip')}
                                            variant="filled"
                                        />
                                        <Input
                                            className={classes.halfFormInput}
                                            placeholder={i18n.t('form.city')}
                                            name="city"
                                            key="city"
                                            type="text"
                                            label={i18n.t('form.city')}
                                            variant="filled"
                                        />
                                    </div>
                                </div>
                                <div className={classes.flexRowContainer}>
                                    <Input
                                        error={errors.email}
                                        className={classes.formInput}
                                        placeholder={i18n.t('form.email')}
                                        name="email"
                                        key="email"
                                        type="text"
                                        label={i18n.t('form.email')}
                                        variant="filled"
                                    />
                                    <Input
                                        className={classes.formInput}
                                        placeholder={i18n.t('form.facebook')}
                                        name="facebook"
                                        key="facebook"
                                        value={values.facebook || ''}
                                        type="text"
                                        label={i18n.t('form.facebook')}
                                        variant="filled"
                                    />
                                </div>
                                <div className={classes.flexRowContainer}>
                                    <Input
                                        className={classes.formInput}
                                        placeholder={i18n.t('form.webpage')}
                                        name="website"
                                        key="website"
                                        type="text"
                                        label={i18n.t('form.webpage')}
                                        variant="filled"
                                    />
                                    <Input
                                        className={classes.formInput}
                                        placeholder={i18n.t('form.instagram')}
                                        name="instagram"
                                        key="instagram"
                                        value={values.instagram || ''}
                                        type="text"
                                        label={i18n.t('form.instagram')}
                                        variant="filled"
                                    />
                                </div>
                                <Input
                                    as="textarea"
                                    fullWidth
                                    name="description"
                                    key="description"
                                    type="text"
                                    variant="filled"
                                    multiline
                                    minRows={4}
                                    disabled
                                    value={truncateString(
                                        values.description?.replace(
                                            regexHelper.removeAllTags,
                                            ''
                                        ) || '',
                                        300
                                    )}
                                    label={
                                        <div
                                            className={classes['flexRowSpaceBetween']}
                                            style={{
                                                width: values.description
                                                    ? width * 1.3
                                                    : width - 28,
                                            }}
                                        >
                                            <span>
                                                {i18n.t('form.detailedDescription')}
                                                {'    '}
                                                {!values.description
                                                    ? `(${i18n.t(
                                                          'form.detailedPlaceHolderDescription'
                                                      )})`
                                                    : ''}
                                            </span>
                                            <IconButton aria-label="pen">
                                                <img
                                                    src={images.icons.penBlack}
                                                    alt="pen-black"
                                                    className={classes.resetSearchIcon}
                                                />
                                            </IconButton>
                                        </div>
                                    }
                                    onClick={() => {
                                        setTextToEdit(values.description);
                                        setValueName('description');
                                        setShowEditor(true);
                                    }}
                                />
                                <SetFieldText field={valueName} text={textToEdit} />
                            </Form>
                        </div>
                        <div
                            key="second-div"
                            className={classes.restaurantDetailsRightContainer}
                            onChange={props.onFormChange}
                        >
                            <h6 className={classes.sectionTitle}>
                                {i18n.t('restaurant.typeOfCuisine').toUpperCase()}
                            </h6>
                            <Autocomplete
                                options={cuisineTypes}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                classes={{
                                    option: classes.dropdownItem,
                                    listbox: classes.listBox,
                                    paper: classes.paper,
                                }}
                                renderOption={(option) => {
                                    const exists = !!values.type.find(
                                        (currentOption: CuisineData) =>
                                            currentOption.id === option.id
                                    );
                                    return (
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                props.onFormChange();
                                                let newValues;
                                                if (exists) {
                                                    newValues = values.type.filter(
                                                        (selectedOption: CuisineData) =>
                                                            selectedOption.id !== option.id
                                                    );
                                                } else {
                                                    newValues = [...values.type, option];
                                                }
                                                setFieldValue('type', newValues);
                                            }}
                                            className={`${classes.width100} ${classes.bottomLine} ${
                                                exists ? classes.selectedDropdownItem : ''
                                            }`}
                                        >
                                            <Checkbox color="primary" checked={exists} />
                                            <span>{option.name}</span>
                                        </div>
                                    );
                                }}
                                renderInput={(params) => (
                                    <>
                                        <MuiTextField
                                            {...params}
                                            name="type"
                                            label={
                                                !values?.type?.length
                                                    ? i18n.t('common.chooseTheType')
                                                    : `${values.type.length} ${i18n.t(
                                                          'restaurant.cuisines'
                                                      )}`
                                            }
                                            error={values.type.length === 0}
                                            variant="filled"
                                        />
                                    </>
                                )}
                            />
                            <h6 className={`${classes.sectionTitle} ${classes.mTop5P}`}>
                                {i18n.t('restaurant.maxTimeForOrder').toUpperCase()}
                            </h6>
                            <Autocomplete
                                options={getOrderPreparationDurations()}
                                getOptionLabel={(option) => option.name}
                                value={{
                                    id: values.ordersDeadline / 30,
                                    duration: values.ordersDeadline,
                                    name:
                                        values.ordersDeadline + ` ${i18n.t('restaurant.minutes')}`,
                                }}
                                getOptionSelected={(option, value) => {
                                    return option.id === value.id;
                                }}
                                classes={{
                                    option: classes.dropdownItem,
                                    listbox: classes.listBox,
                                    paper: classes.paper,
                                }}
                                onChange={(e, val) => {
                                    if (val) {
                                        setFieldValue('ordersDeadline', val?.duration);
                                        props.onFormChange();
                                    }
                                }}
                                renderOption={(option) => {
                                    return (
                                        <div
                                            style={{ padding: '10px' }}
                                            className={`${classes.width100} ${classes.bottomLine}`}
                                        >
                                            <span>{option.name}</span>
                                        </div>
                                    );
                                }}
                                renderInput={(params) => (
                                    <>
                                        <MuiTextField
                                            {...params}
                                            label={i18n.t('restaurant.duration')}
                                            variant="filled"
                                        />
                                    </>
                                )}
                            />

                            <h6
                                className={renderMultipleClassNames([
                                    classes.sectionTitle,
                                    classes.mTop10P,
                                ])}
                                style={{
                                    color: !hasAnyService(values)
                                        ? theme.palette.error.main
                                        : 'black',
                                }}
                            >
                                {i18n.t('form.services').toUpperCase() + ' '}
                                {!hasAnyService(values) && (
                                    <span style={{ color: theme.palette.error.main }}>
                                        - {i18n.t('form.errors.onlyRequired')}
                                    </span>
                                )}
                            </h6>
                            <div className={classes.serviceSectionContainer}>
                                <div className={classes.serviceText}>
                                    <Input
                                        component={FormikFields.CheckboxWithLabel}
                                        variant="filled"
                                        name={'hasHomeDelivery'}
                                        type="checkbox"
                                        Label={{ label: i18n.t('form.homeDelivery') }}
                                        placeholder={i18n.t('form.homeDelivery')}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        color="primary"
                                    />
                                </div>
                                <Divider className={classes.divider} />
                                <img
                                    alt="home-delivery"
                                    src={
                                        values.hasHomeDelivery
                                            ? images.icons.chartHomeDelivery
                                            : images.icons.homeDeliveryDisabled
                                    }
                                    className={classes.homeDeliveryIcon}
                                />
                            </div>
                            <div className={classes.serviceSectionContainer}>
                                <div className={classes.serviceText}>
                                    <Input
                                        component={FormikFields.CheckboxWithLabel}
                                        variant="filled"
                                        name={'hasTakeAway'}
                                        type="checkbox"
                                        Label={{ label: i18n.t('form.takeAway') }}
                                        placeholder={i18n.t('form.takeAway')}
                                        color="primary"
                                    />
                                </div>
                                <Divider className={classes.divider} />
                                <img
                                    alt="take-away"
                                    src={
                                        values.hasTakeAway
                                            ? images.icons.chartTakeAway
                                            : images.icons.takeAwayDisabled
                                    }
                                    className={classes.takeAwayIcon}
                                />
                            </div>
                            <div className={classes.serviceSectionContainer}>
                                <div className={classes.serviceText}>
                                    <Input
                                        component={FormikFields.CheckboxWithLabel}
                                        variant="filled"
                                        name={'hasOnSite'}
                                        type="checkbox"
                                        Label={{ label: i18n.t('form.jamesVorort') }}
                                        placeholder={i18n.t('form.jamesVorort')}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        color="primary"
                                    />
                                </div>
                                <Divider className={classes.divider} />
                                <img
                                    alt="on-site"
                                    src={
                                        values.hasOnSite
                                            ? images.icons.chartVorort
                                            : images.icons.hasOnSiteDisabled
                                    }
                                    className={classes.takeOutIcon}
                                />
                            </div>
                            <div
                                className={helperFunctions.renderMultipleClassNames([
                                    classes.flexRowSpaceBetween,
                                    classes.pTop5P,
                                ])}
                            >
                                <h6 className={classes.sectionTitle}>
                                    {i18n.t('common.workingHours').toUpperCase()}
                                </h6>
                                <BasicThreeDotsMenu
                                    items={[
                                        <div
                                            className={classes.threeDotsMenuItemContainer}
                                            onClick={() => displayWorkingHoursModal('workdays')}
                                        >
                                            <EditIcon className={classes.threeDotsItemIcon} />
                                            <ListItemText primary={i18n.t('common.edit')} />
                                        </div>,
                                    ]}
                                />
                            </div>

                            {getDayList(initialValues.openHours).map((day) => (
                                <div className={classes.serviceSectionContainer} key={day.name}>
                                    <span className={classes.serviceSectionTitle}>{day.name}</span>
                                    <Divider className={classes.halfDivider} />
                                    <div className={classes.workingHoursContainer}>
                                        {day.isClosed ? (
                                            <span className={classes.hourText}>
                                                {day.isClosed
                                                    ? i18n.t('common.closed')
                                                    : '00.00 - 23.59'}
                                            </span>
                                        ) : (
                                            day.workingHours.map(
                                                (date: { from: string; to: string }, i: number) => (
                                                    <span key={i} className={classes.hourText}>
                                                        {getFormattedHour(date)}
                                                    </span>
                                                )
                                            )
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </Formik>
            <TextEditor
                isOpen={showEditor}
                text={textToEdit}
                onClose={() => setShowEditor(false)}
                onSave={saveTextFromEditor}
            />
        </div>
    );
}
