import * as React from 'react';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as yup from 'yup';
import i18n from 'i18n-js';
import { Button, TextField, DialogActions } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { MenuCreateModel } from 'api/models';
import { MenuType } from 'constants/enums';
import { MenuData } from 'store/menusStore/reducer';
import { default as menusStore } from 'store/menusStore';
import menuDetailsStore from 'store/menuDetailsStore';
import { Input, TimePickerModal } from 'components/shared';
import { formatTime } from 'helpers/datetimeHelper';
import globalStore from 'store/globalStore';
import { trimLeftAndRight } from 'helpers/helperFunctions';
import ImageContainer, {
    ImageContainerRefProps,
} from 'components/shared/components/ImageContainer';
import { useStyles } from '../styles';
import { useAppSelector } from 'index';

interface EditMenuFormProps {
    menu: MenuData;
    cancel: any;
    isDetails: boolean;
}

interface InitialTime {
    fromHours: string;
    fromMinutes: string;
    toHours: string;
    toMinutes: string;
}

export default function EditMenuForm(props: EditMenuFormProps) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const menus = useAppSelector((state) => state.menus);
    const [showTimePicker, setShowTimePicker] = useState(false);
    const initalFormValues: MenuCreateModel = {
        name: props.menu.name,
        description: props.menu.description,
        availableFrom: props.menu.availableFrom,
        availableTo: props.menu.availableTo,
        photo: props.menu.photo,
        type: props.menu.type,
        price: props.menu.price,
        position: props.menu.position || 0,
        discount: props.menu.discount || 0,
    };
    const [anchorElement, setAnchorElement] = useState<any>(null);
    const initialTimeRange = useRef(
        getInitialTime(props.menu.availableFrom, props.menu.availableTo)
    );
    const childRef = useRef<ImageContainerRefProps>(null);

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Name',
                })
            )
            .test(
                'existsCheck',
                i18n.t('form.errors.alreadyTaken'),
                (value: any) =>
                    !menus.menus.some(
                        (menu) => menu.name.trim().toUpperCase() === value?.trim().toUpperCase()
                    )
            ),
        /* description: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Description',
            })
        ), */
        position: yup.number().typeError(i18n.t('form.errors.onlyDigits')).integer().min(0),
        discount: yup.number().typeError(i18n.t('form.errors.onlyDigits')),
        price: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .when([], {
                is: () => props.menu.type === MenuType.Daily,
                then: yup.number().required(
                    i18n.t('form.errors.required', {
                        name: 'Price',
                    })
                ),
                otherwise: yup.number().notRequired(),
            }),
        availableFrom: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .when([], {
                is: () => props.menu.type === MenuType.Special,
                then: yup
                    .number()
                    .required(
                        i18n.t('form.errors.required', {
                            name: 'Discount',
                        })
                    )
                    .test(
                        'not zero',
                        i18n.t('form.errors.greaterZero'),
                        (value: any) => value !== 0
                    ),
                otherwise: yup.number().notRequired(),
            }),
        availableTo: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .when([], {
                is: () => props.menu.type === MenuType.Special,
                then: yup
                    .number()
                    .required(
                        i18n.t('form.errors.required', {
                            name: 'Discount',
                        })
                    )
                    .test(
                        'not zero',
                        i18n.t('form.errors.greaterZero'),
                        (value: any) => value !== 0
                    ),
                otherwise: yup.number().notRequired(),
            }),
    });

    const submitPromise = async (model: MenuCreateModel) => {
        let createMenuModel: MenuCreateModel = {
            name: model.name,
            description: model.description,
            price: model.price,
            type: model.type,
            photo: model.photo,
            availableFrom: model.availableFrom,
            availableTo: model.availableTo,
            position: model.position,
            discount: model.discount,
        };
        dispatch(menusStore.actionCreators.editMenu(createMenuModel, props.menu.id));
    };

    const openHoursView = (from: number, to: number) =>
        `${formatTime(new Date(from))} - ${formatTime(new Date(to))}`;

    function getInitialTime(timeStampFrom: number, timeStampTo: number) {
        const dateTimeFrom = new Date(timeStampFrom);
        const dateTimeTo = new Date(timeStampTo);
        return {
            fromHours: handleNumberStartZero(dateTimeFrom.getHours().toString()),
            fromMinutes: handleNumberStartZero(dateTimeFrom.getMinutes().toString()),
            toHours: handleNumberStartZero(dateTimeTo.getHours().toString()),
            toMinutes: handleNumberStartZero(dateTimeTo.getMinutes().toString()),
        } as InitialTime;
    }

    function handleNumberStartZero(number: string) {
        return number.length > 1 ? number : `0${number}`;
    }

    function getNewTime(newTime: string) {
        const [newHours, newMinutes] = newTime.split('.');
        return new Date(0).setHours(+newHours, +newMinutes);
    }

    function handleTimeClick(e: any) {
        setAnchorElement(e.target.getBoundingClientRect());
        setShowTimePicker(!showTimePicker);
    }

    return (
        <>
            <Formik
                initialValues={initalFormValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { resetForm }) => {
                    const photo = await childRef?.current?.uploadImage();
                    if (photo && photo?.length > 0) values.photo = photo;
                    submitPromise({
                        ...values,
                        name: trimLeftAndRight(values.name),
                        description: trimLeftAndRight(values.description),
                    }).then(() => {
                        resetForm();
                        props.cancel();
                        props.isDetails &&
                            dispatch(menuDetailsStore.actionCreators.getDetails(props.menu.id));
                    });
                    dispatch(globalStore.actionCreators.hideSpiner());
                }}
            >
                {({ errors, values, setFieldValue, submitForm, dirty }) => (
                    <Form className={classes.editMenuRoot}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <>
                                {props.menu.type !== MenuType.Special && (
                                    <div className={classes.photoContainer}>
                                        <ImageContainer ref={childRef} photo={values.photo} />
                                    </div>
                                )}
                                <br />
                                <Input
                                    error={errors.name}
                                    variant="filled"
                                    fullWidth={true}
                                    name={`name`}
                                    type="text"
                                    label={i18n.t('form.name')}
                                    placeholder={i18n.t('form.name')}
                                />
                                <br />
                                {props.menu.type === MenuType.Daily ||
                                    (props.menu.type === MenuType.Special && (
                                        <div className={classes.flexRowSpaceBetween}>
                                            <TextField
                                                variant="filled"
                                                value={openHoursView(
                                                    values.availableFrom,
                                                    values.availableTo
                                                )}
                                                label={i18n.t('common.openHours')}
                                                placeholder={openHoursView(
                                                    values.availableFrom,
                                                    values.availableTo
                                                )}
                                                onClick={(e: any) => handleTimeClick(e)}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                style={{
                                                    width: '47%',
                                                }}
                                            />
                                            <TimePickerModal
                                                initialFromHour={initialTimeRange.current.fromHours}
                                                initialFromMinute={
                                                    initialTimeRange.current.fromMinutes
                                                }
                                                initialToHour={initialTimeRange.current.toHours}
                                                initialToMinute={initialTimeRange.current.toMinutes}
                                                isVisible={showTimePicker}
                                                onClose={(selectedHours) => {
                                                    setFieldValue(
                                                        'availableFrom',
                                                        getNewTime(selectedHours.from)
                                                    );
                                                    setFieldValue(
                                                        'availableTo',
                                                        getNewTime(selectedHours.to)
                                                    );
                                                    setShowTimePicker(false);
                                                }}
                                                clickedElementRect={anchorElement}
                                            />
                                            {props.menu.type === MenuType.Special && (
                                                <Input
                                                    error={errors.discount}
                                                    variant="filled"
                                                    name="discount"
                                                    type="text"
                                                    label={i18n.t('form.discount') + ' %'}
                                                    placeholder={i18n.t('form.specifyDiscount')}
                                                    className={classes.width48}
                                                />
                                            )}
                                            {props.menu.type !== MenuType.Special && (
                                                <Input
                                                    error={errors.price}
                                                    variant="filled"
                                                    name={`price`}
                                                    type="text"
                                                    label={i18n.t('form.price')}
                                                    placeholder={i18n.t('form.price')}
                                                    style={{
                                                        width: '47%',
                                                    }}
                                                />
                                            )}
                                        </div>
                                    ))}
                                <Input
                                    error={errors.description}
                                    variant="filled"
                                    fullWidth={true}
                                    name={`description`}
                                    type="text"
                                    label={i18n.t('form.description')}
                                    placeholder={i18n.t('form.description')}
                                />
                                {/* <Input
                                    error={errors.position}
                                    variant="filled"
                                    fullWidth={true}
                                    name={`position`}
                                    type="text"
                                    label={i18n.t('form.position')}
                                    placeholder={i18n.t('form.position')}
                                /> */}
                            </>
                        </MuiPickersUtilsProvider>
                        <DialogActions>
                            <Button type="button" color="primary" onClick={props.cancel}>
                                {i18n.t('button.cancel')}
                            </Button>
                            <Button
                                onClick={submitForm}
                                variant="contained"
                                color="primary"
                                disabled={!dirty}
                            >
                                {i18n.t('button.save')}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </>
    );
}
