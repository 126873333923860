import React from 'react';

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import i18n from 'i18n-js';

import { MenuData } from 'store/menusStore/reducer';
import { EditMenuForm } from '../MenusPage/components';
import { MenuType } from 'constants/enums';
import { images } from 'assets';
import { useStyles } from './styles';

interface MenuEditModalProps {
    menu: MenuData;
    isOpen: boolean;
    isDetails: boolean;
    handleClose: () => void;
}

export const MenuEditModal = (props: MenuEditModalProps) => {
    const classes = useStyles();

    return (
        <Dialog open={props.isOpen} aria-labelledby="form-dialog-title" className={classes.dialog}>
            {props.menu.type === MenuType.Standard && (
                <DialogTitle id="form-dialog-title">
                    {i18n.t('restaurant.editStandartMenu')}
                </DialogTitle>
            )}
            {props.menu.type === MenuType.Daily && (
                <DialogTitle id="form-dialog-title">
                    {i18n.t('restaurant.editDailyMenu')}
                    <img src={images.icons.breakfastLogo} alt="breakfast" />
                </DialogTitle>
            )}
            {props.menu.type === MenuType.Special && (
                <DialogTitle id="form-dialog-title">
                    {i18n.t('restaurant.editSpecialMenu')}
                </DialogTitle>
            )}
            <DialogContent>
                <EditMenuForm
                    cancel={props.handleClose}
                    menu={props.menu}
                    isDetails={props.isDetails}
                />
            </DialogContent>
        </Dialog>
    );
};
