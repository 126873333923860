import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import i18n from 'i18n-js';
import { Select, FormControl, MenuItem } from '@material-ui/core';

import { default as ordersStore } from 'store/ordersStore';
import { default as restaurantStore } from 'store/restaurantDetailsStore';
import { useStyles } from './styles';
import { useAppSelector } from 'index';
import { OrderStatus } from 'constants/enums';

export default function ResponsibleUserSelect() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const orderDetails = useAppSelector((state) => state.orders.orderDetails);
    const responsibleUsers = useAppSelector((state) => state.restaurantDetails.responsibleUsers);
    const [responsibleUser, setResponsibleUser] = useState(orderDetails.responsibleUserId || '');

    useEffect(() => {
        if (!responsibleUsers.length) {
            dispatch(restaurantStore.actionCreators.getRestaurantResponsibleUsers());
        }
        setResponsibleUser(orderDetails.responsibleUserId || '');
    }, [orderDetails.responsibleUserId]);

    return (
        <>
            <span className={classes.orderDetailsHeadingText} style={{ marginBottom: 20 }}>
                {i18n.t('common.assignedTo')}
            </span>
            <FormControl fullWidth variant="filled">
                <Select
                    defaultValue={''}
                    placeholder={'Select to assign'}
                    displayEmpty
                    value={responsibleUser}
                    renderValue={() => responsibleUsers.find((x) => x.id === responsibleUser)?.name}
                    onChange={(e) => {
                        dispatch(
                            ordersStore.actionCreators.assignOrder(
                                orderDetails.id,
                                orderDetails.restaurantId!,
                                e.target.value as string
                            )
                        );
                    }}
                    className={classes.responsibleUserSelector}
                    disabled={
                        orderDetails.status === OrderStatus.Cancel || responsibleUsers?.length < 1
                    }
                >
                    {responsibleUsers.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                            {user.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
}
