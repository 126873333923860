import * as React from 'react';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as yup from 'yup';
import i18n from 'i18n-js';
import { Button, TextField, DialogActions } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Form, Formik } from 'formik';
import DateFnsUtils from '@date-io/date-fns';

import { MenuCreateModel } from 'api/models';
import { MenuType } from 'constants/enums';
import { Input, TimePickerModal } from 'components/shared';
import { dateTimeIgnoreTimezone, formatTime } from 'helpers/datetimeHelper';
import globalStore from 'store/globalStore';
import ImageContainer, { ImageContainerRefProps } from '../../../shared/components/ImageContainer';
import { useStyles } from '../styles';
import { useAppSelector } from 'index';

interface AddMenuFormProps {
    menuType: MenuType;
    cancel: any;
    create: any;
}

/* export interface AddMenuModel {
    name: string;
    description: string;
    availableFrom: number;
    availableTo: number;
    photo: string;
    type: MenuType;
    price?: number;
    position: number;
    discount: number;
} */

export interface InitialTime {
    fromHours: string;
    fromMinutes: string;
    toHours: string;
    toMinutes: string;
}

export default function AddMenuForm(props: AddMenuFormProps) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const menus = useAppSelector((state) => state.menus);
    const childRef = useRef<ImageContainerRefProps>(null);
    const initalFormValues: MenuCreateModel = {
        name: '',
        description: '',
        availableFrom: 0,
        availableTo: 0,
        photo: '',
        type: props.menuType,
        price: undefined,
        position: 0,
        discount: 0,
    };
    const [showTimePicker, setShowTimePicker] = useState(false);
    const [anchorElement, setAnchorElement] = useState<any>(null);
    const initialTimeRange = useRef(
        getInitialTime(initalFormValues.availableFrom, initalFormValues.availableTo)
    );

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Name',
                })
            )
            .test(
                'existsCheck',
                i18n.t('form.errors.alreadyTaken'),
                (value: any) =>
                    !menus.menus.some(
                        (menu) => menu.name.trim().toUpperCase() === value?.trim().toUpperCase()
                    )
            ),

        /* description: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Description',
            })
        ), */
        position: yup.number().typeError(i18n.t('form.errors.onlyDigits')).integer().min(0),
        discount: yup.number().typeError(i18n.t('form.errors.onlyDigits')),
        price: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .when([], {
                is: () => props.menuType === MenuType.Daily,
                then: yup
                    .number()
                    .required(
                        i18n.t('form.errors.required', {
                            name: 'Price',
                        })
                    )
                    .min(0.1),
                otherwise: yup.number().notRequired(),
            }),
        availableFrom: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .when([], {
                is: () => props.menuType === MenuType.Special,
                then: yup
                    .number()
                    .required(
                        i18n.t('form.errors.required', {
                            name: 'Discount',
                        })
                    )
                    .test(
                        'not zero',
                        i18n.t('form.errors.greaterZero'),
                        (value: any) => value !== 0
                    ),
                otherwise: yup.number().notRequired(),
            }),
        availableTo: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .when([], {
                is: () => props.menuType === MenuType.Special,
                then: yup
                    .number()
                    .required(
                        i18n.t('form.errors.required', {
                            name: 'Discount',
                        })
                    )
                    .test(
                        'not zero',
                        i18n.t('form.errors.greaterZero'),
                        (value: any) => value !== 0
                    ),
                otherwise: yup.number().notRequired(),
            }),
    });

    const submitPromise = async (model: MenuCreateModel) => {
        let createMenuModel: MenuCreateModel = {
            name: model.name,
            description: model.description,
            price: model.price,
            type: model.type,
            photo: model.photo,
            availableFrom: model.availableFrom,
            availableTo: model.availableTo,
            position: model.position,
            discount: model.discount,
        };
        props.create(createMenuModel);
    };

    const openHoursView = (from: number, to: number) =>
        from === 0 && to === 0
            ? `${formatTime(dateTimeIgnoreTimezone(from))} - ${formatTime(
                  dateTimeIgnoreTimezone(to)
              )}`
            : `${formatTime(new Date(from))} - ${formatTime(new Date(to))}`;

    function getInitialTime(timeStampFrom: number, timeStampTo: number) {
        const dateTimeFrom = new Date(timeStampFrom);
        const dateTimeTo = new Date(timeStampTo);
        return {
            fromHours: handleNumberStartZero(dateTimeFrom.getHours().toString()),
            fromMinutes: handleNumberStartZero(dateTimeFrom.getMinutes().toString()),
            toHours: handleNumberStartZero(dateTimeTo.getHours().toString()),
            toMinutes: handleNumberStartZero(dateTimeTo.getMinutes().toString()),
        } as InitialTime;
    }

    function handleNumberStartZero(number: string) {
        return number.length > 1 ? number : `0${number}`;
    }

    function getNewTime(newTime: string) {
        const [newHours, newMinutes] = newTime.split('.');
        return new Date(0).setHours(+newHours, +newMinutes);
    }

    function handleTimeClick(e: any) {
        setAnchorElement(e.target.getBoundingClientRect());
        setShowTimePicker(!showTimePicker);
    }

    return (
        <>
            <Formik
                initialValues={initalFormValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { resetForm }) => {
                    dispatch(globalStore.actionCreators.showSpiner());
                    let photo = '';
                    try {
                        if (childRef?.current) {
                            photo = await childRef?.current?.uploadImage();
                        }
                        if (photo.length > 0) {
                            values.photo = photo;
                        }
                        submitPromise(values).then(() => {
                            resetForm();
                            props.cancel();
                        });
                    } catch (error) {
                        dispatch(globalStore.actionCreators.showToaster('error', error));
                    } finally {
                        dispatch(globalStore.actionCreators.hideSpiner());
                    }
                }}
            >
                {({ errors, values, setFieldValue, submitForm, dirty }) => (
                    <Form className={classes.addMenuRoot}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <>
                                {props.menuType !== MenuType.Special && (
                                    <div className={classes.photoContainer}>
                                        <ImageContainer ref={childRef} photo={values.photo} />
                                    </div>
                                )}
                                <br />
                                <Input
                                    error={errors.name}
                                    variant="filled"
                                    fullWidth={true}
                                    name={`name`}
                                    type="text"
                                    label={i18n.t('form.name')}
                                    placeholder={i18n.t('form.name')}
                                />
                                <br />
                                {props.menuType === MenuType.Daily ||
                                    (props.menuType === MenuType.Special && (
                                        <div className={classes.flexRowSpaceBetween}>
                                            <TextField
                                                variant="filled"
                                                value={openHoursView(
                                                    values.availableFrom,
                                                    values.availableTo
                                                )}
                                                label={i18n.t('common.setTheTime')}
                                                placeholder={openHoursView(
                                                    values.availableFrom,
                                                    values.availableTo
                                                )}
                                                onClick={(e: any) => handleTimeClick(e)}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                className={classes.width48}
                                                error={
                                                    !!errors.availableFrom || !!errors.availableTo
                                                }
                                            />
                                            <TimePickerModal
                                                initialFromHour={initialTimeRange.current.fromHours}
                                                initialFromMinute={
                                                    initialTimeRange.current.fromMinutes
                                                }
                                                initialToHour={initialTimeRange.current.toHours}
                                                initialToMinute={initialTimeRange.current.toMinutes}
                                                isVisible={showTimePicker}
                                                onClose={(selectedHours) => {
                                                    setFieldValue(
                                                        'availableFrom',
                                                        getNewTime(selectedHours.from)
                                                    );
                                                    setFieldValue(
                                                        'availableTo',
                                                        getNewTime(selectedHours.to)
                                                    );
                                                    setShowTimePicker(false);
                                                }}
                                                clickedElementRect={anchorElement}
                                            />
                                            {props.menuType === MenuType.Special && (
                                                <Input
                                                    error={errors.discount}
                                                    variant="filled"
                                                    name="discount"
                                                    type="text"
                                                    label={i18n.t('form.discount') + ' %'}
                                                    placeholder={i18n.t('form.specifyDiscount')}
                                                    className={classes.width48}
                                                />
                                            )}
                                        </div>
                                    ))}

                                {props.menuType === MenuType.Daily && (
                                    <Input
                                        error={errors.price}
                                        variant="filled"
                                        name={`price`}
                                        type="text"
                                        label={i18n.t('form.price')}
                                        placeholder={i18n.t('form.price')}
                                        className={classes.width48}
                                    />
                                )}

                                <Input
                                    error={errors.description}
                                    variant="filled"
                                    fullWidth={true}
                                    name={`description`}
                                    type="text"
                                    label={i18n.t('form.description')}
                                    placeholder={i18n.t('form.description')}
                                />
                                {/* <Input
                                    error={errors.position}
                                    variant="filled"
                                    fullWidth={true}
                                    name={`position`}
                                    type="text"
                                    label={i18n.t('form.position')}
                                    placeholder={i18n.t('form.position')}
                                /> */}
                            </>
                        </MuiPickersUtilsProvider>
                        <DialogActions>
                            <Button type="button" color="primary" onClick={props.cancel}>
                                {i18n.t('button.cancel')}
                            </Button>
                            <Button
                                onClick={submitForm}
                                variant="contained"
                                color="primary"
                                disabled={!dirty}
                            >
                                {i18n.t('button.create')}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </>
    );
}
