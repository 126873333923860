import React, { useRef, useState } from 'react';

import i18n from 'i18n-js';

import { Checkbox, Radio } from '@material-ui/core';

import { DishData, ModifiedIngredient } from 'store/menuDetailsStore/reducer';
import { useStyles } from '../styles';
import { useAppSelector } from 'index';
import { IngredientModel } from 'api/models';
import { truncateString, priceStyleHelper } from 'helpers/helperFunctions';
import { images } from 'assets';
import { theme } from 'config/theme';
import DishDietaryView from 'components/shared/components/DishDietaryView';
import { SpicinessLevels, Diets, DietaryLaws } from 'constants/enums';
import { getSpicinessLevels, getDiets, getDietaryLaws } from 'constants/maps';
import { PopupConfirmation } from 'components/shared';

interface DishIngredientItemProps {
    defaultDish: DishData;
    hidable?: boolean;
    open?: boolean;
    onValueChange: (newDish: DishData) => void;
}

export default function DishIngredientItem(props: DishIngredientItemProps) {
    const classes = useStyles();

    const allergens = useAppSelector((state) => state.ingredients.allergies);
    const menus = useAppSelector((state) => state.menus);

    const [dish, setDish] = useState(getDefaultDish());
    const [isOpen, setIsOpen] = useState(props.open);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [selectedSize, setSelectedSize] = useState(props.defaultDish?.size || '');

    const defaultDishValues = useRef({ ...getDefaultDish() }).current;

    function getDefaultDish() {
        const defaultIngredients: any = [];
        if (defaultIngredients && props.defaultDish?.ingredients) {
            props.defaultDish.ingredients.forEach((ingredient: IngredientModel) => {
                defaultIngredients.push({
                    ...ingredient,
                    isAdditionChecked: ingredient.isAdditionChecked ?? ingredient.isAddition,
                    isRemovalChecked: ingredient.isRemovalChecked ?? ingredient.isRemoval,
                });
            });
            props.defaultDish.ingredients = defaultIngredients;
        }

        return {
            ...props.defaultDish,
        };
    }

    function setDishSizes(newDish: DishData, newSize: string) {
        if (!!dish.additionalSizes.length) {
            newDish.size = newSize;
            const newAddtitionalSizes = dish.additionalSizes.filter(
                (size) => size.size !== newSize
            );
            newAddtitionalSizes.push({ size: dish.size, price: dish.price });
            newDish.additionalSizes = newAddtitionalSizes;
        }
    }

    function changeSize(newSize: string) {
        const newDish = { ...dish };
        setDishSizes(newDish, newSize);
        const targetDish = menus.dishList.find((x) => x.id === dish.id)!;
        let newPrice = targetDish.additionalSizes.find((size) => size.size === newSize)?.price || 0;
        if (!!!newPrice) {
            newPrice = targetDish.price;
        }
        if (!!dish.modifiedIngredients?.length) {
            dish.modifiedIngredients?.forEach((modIngr) => {
                const ingredient = dish.ingredients.find((ingr) => ingr.id === modIngr.id)!;
                if (modIngr.isRemovalChecked) {
                    newPrice -= ingredient.price;
                } else if (modIngr.isAdditionChecked) {
                    newPrice += ingredient.price;
                }
            });
        }
        newDish.price = newPrice;
        newDish.modified = true;
        props.onValueChange({ ...newDish });
        setDish({ ...newDish });
    }

    function changeValue(key: string, id: string | undefined, value: boolean | undefined) {
        if (id) {
            const newDish = { ...dish };
            setDishSizes(newDish, selectedSize);

            const index = newDish.ingredients.findIndex((each) => each.id === id);
            const modifiedIngredient = newDish.modifiedIngredients?.find((each) => each.id === id);
            let updatedIngredients: ModifiedIngredient[] = [];
            if (modifiedIngredient) {
                updatedIngredients =
                    newDish.modifiedIngredients?.filter((each) => each.id !== id) || [];
            } else {
                if (!newDish.modifiedIngredients) {
                    newDish.modifiedIngredients = [];
                }

                updatedIngredients = [
                    ...newDish.modifiedIngredients,
                    {
                        id,
                        [key]: value,
                    },
                ];
            }

            const targetDish = menus.dishList.find((x) => x.id === dish.id)!;
            let newPrice =
                targetDish.additionalSizes.find((size) => size.size === newDish.size)?.price || 0;
            if (!!!newPrice) {
                newPrice = targetDish.price;
            }
            updatedIngredients.forEach((modIngr) => {
                const ingredient = newDish.ingredients.find((ingr) => ingr.id === modIngr.id)!;
                if (modIngr.isRemovalChecked) {
                    newPrice -= ingredient.price;
                } else if (modIngr.isAdditionChecked) {
                    newPrice += ingredient.price;
                }
            });

            if (newPrice <= 0) {
                setShowConfirmationPopup(true);
            } else {
                newDish.price = newPrice;
                newDish.modifiedIngredients = [...updatedIngredients];
                if (
                    JSON.stringify(newDish.ingredients) !==
                    JSON.stringify(defaultDishValues.ingredients)
                ) {
                    newDish.modified = true;
                    newDish.ingredients[index].modified = true;
                } else {
                    delete newDish.modified;
                    delete newDish.ingredients[index].modified;
                }
                props.onValueChange({ ...newDish });
                setDish({ ...newDish });
            }
        }
    }

    function getAllergenIcons() {
        return dish.allergens?.map((id: string) => {
            const allergen = allergens.find((each) => each.id === id);
            return (
                <img
                    key={id}
                    src={allergen?.icon}
                    alt={allergen?.name}
                    style={{ height: '24px', marginLeft: 7 }}
                />
            );
        });
    }

    function getFixedIngredients(defDish: any = null) {
        const dishToFilter = defDish ? defDish : dish;
        return dishToFilter.ingredients?.filter(
            (each: IngredientModel) => each.state === 0 && !each.isRemoval
        );
    }

    function getChangeOrDeleteIngredients(defDish: any = null) {
        const dishToFilter = defDish ? defDish : dish;
        return dishToFilter.ingredients?.filter(
            (each: IngredientModel) => each.state === 0 && each.isRemoval
        );
    }

    function getAdditionalIngredients(defDish: any = null) {
        const dishToFilter = defDish ? defDish : dish;
        return dishToFilter.ingredients?.filter((each: IngredientModel) => each.state === 1);
    }

    function getValue(id: string | undefined, key: string) {
        const modifiedIngredient = dish.modifiedIngredients?.find((each) => each.id === id);
        if (modifiedIngredient) {
            const result: boolean = !!modifiedIngredient[key as keyof typeof modifiedIngredient];
            return key === 'isRemovalChecked' ? !result : result;
        } else {
            const target = dish.ingredients.find((each) => each.id === id)?.[
                key as keyof typeof modifiedIngredient
            ];

            return key === 'isRemovalChecked' ? !!target : !!!target;
        }
    }

    function prepareSizes() {
        if (!!dish.additionalSizes.length) {
            const targetDish = menus.dishList.find((x) => x.id === dish.id)!;
            return (
                <>
                    <div className={`${classes.subTitle} ${classes.mBot10}`}>
                        {i18n.t('common.chooseSize').toUpperCase()}
                    </div>
                    <div style={{ marginBottom: 10, paddingRight: 20 }}>
                        <div className={classes.flexRowSpaceBetween}>
                            <div className={classes.flexRowCenter} style={{ marginBottom: 5 }}>
                                <Radio
                                    style={{ padding: 0 }}
                                    color="primary"
                                    checked={targetDish.size === selectedSize}
                                    onChange={() => {
                                        console.log(targetDish.size);
                                        changeSize(targetDish.size);
                                        setSelectedSize(targetDish.size);
                                    }}
                                />
                                <span className={classes.sizeName}>{targetDish.size}</span>
                            </div>
                            <span className={classes.sizePrice}>
                                {priceStyleHelper(targetDish.price)} CHF
                            </span>
                        </div>
                        {targetDish.additionalSizes.map((size) => (
                            <div
                                key={size.size}
                                className={classes.flexRowSpaceBetween}
                                style={{ marginBottom: 5 }}
                            >
                                <div>
                                    <Radio
                                        style={{ padding: 0 }}
                                        color="primary"
                                        checked={size.size === selectedSize}
                                        onChange={() => {
                                            changeSize(size.size);
                                            setSelectedSize(size.size);
                                        }}
                                    />
                                    <span className={classes.sizeName}>{size.size}</span>
                                </div>
                                <span className={classes.sizePrice}>
                                    {priceStyleHelper(size.price)} CHF
                                </span>
                            </div>
                        ))}
                    </div>
                </>
            );
        } else {
            return null;
        }
    }

    return (
        <div className={classes.dishIngredientItemContainer}>
            <div className={classes.dishIngredientItemHeader}>
                <div
                    onClick={() => setIsOpen(!isOpen)}
                    className={`${classes.flexRowSpaceBetween} ${classes.mBot20}`}
                    style={{ cursor: props.hidable ? 'pointer' : 'auto' }}
                >
                    <div className={classes.flexRow}>
                        <span className={classes.dishTitle}>{truncateString(dish.name, 25)}</span>
                        <div style={{ marginLeft: 3 }}>
                            <DishDietaryView<SpicinessLevels>
                                value={dish.spicinessLevel}
                                map={getSpicinessLevels()}
                                className={classes.mLeft5}
                            />
                            <DishDietaryView<Diets>
                                value={dish.diet}
                                map={getDiets()}
                                className={classes.mLeft5}
                            />
                            <DishDietaryView<DietaryLaws>
                                value={dish.dietaryLaw}
                                map={getDietaryLaws()}
                                className={classes.mLeft5}
                            />
                        </div>
                    </div>
                    {props.hidable && (
                        <img
                            src={images.icons.arrowDropdown}
                            alt="arrow"
                            style={{
                                marginRight: 20,
                                transform: isOpen ? 'rotate(180deg)' : 'rotate(360deg)',
                            }}
                        />
                    )}
                </div>
                {isOpen && (
                    <>
                        <span className={classes.dishDescription}>
                            {truncateString(dish.shortDescription, 30)}
                        </span>
                        <div style={{ marginTop: 20, marginLeft: -8 }}>{getAllergenIcons()}</div>
                    </>
                )}
            </div>
            <div className={classes.divider} />
            {prepareSizes()}

            {isOpen && (
                <div style={{ paddingRight: 20 }}>
                    {getFixedIngredients()?.length > 0 && (
                        <>
                            <div className={classes.flexRowAlignCenter}>
                                <span className={`${classes.subTitle} ${classes.mBot10}`}>
                                    {i18n.t('restaurant.fixedIngredient').toUpperCase()}
                                </span>
                                <img
                                    src={images.icons.lock}
                                    alt={'lock'}
                                    style={{ marginLeft: 8, marginBottom: -2 }}
                                />
                            </div>
                            <div className={classes.mBot10}>
                                {getFixedIngredients()?.map((each: IngredientModel) => {
                                    return (
                                        <div
                                            className={`${classes.flexRowSpaceBetween} ${classes.ingredientText} ${classes.mTop10}`}
                                            key={each.id}
                                        >
                                            <span>{each.name ?? 'No Name'}</span>
                                            <span>
                                                {each.amount}
                                                {each.unitOfMeasure}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                    {getChangeOrDeleteIngredients()?.length > 0 && (
                        <>
                            <span className={`${classes.subTitle} ${classes.mBot10}`}>
                                {i18n.t('restaurant.changeOrDelete').toUpperCase()}
                            </span>
                            <div>
                                {getChangeOrDeleteIngredients()?.map((each: IngredientModel) => {
                                    return (
                                        <div
                                            key={each.id}
                                            className={`${classes.flexRowSpaceBetween} ${classes.ingredientText}`}
                                        >
                                            <div className={classes.flexRowSpaceBetween}>
                                                <Checkbox
                                                    style={{ marginLeft: -10 }}
                                                    checked={getValue(each.id, 'isRemovalChecked')}
                                                    onChange={() => {
                                                        changeValue(
                                                            'isRemovalChecked',
                                                            each.id,
                                                            each.isRemovalChecked
                                                        );
                                                    }}
                                                />
                                                <span>
                                                    {each.name ?? 'No Name'}{' '}
                                                    <span
                                                        style={{
                                                            color: theme.palette.primary.main,
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        {!!each.price && '  ' + each.price + ' CHF'}
                                                    </span>
                                                </span>
                                            </div>
                                            <span>
                                                {each.amount}
                                                {each.unitOfMeasure}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                    {getAdditionalIngredients()?.length > 0 && (
                        <>
                            <span className={`${classes.subTitle} ${classes.mBot10}`}>
                                {i18n.t('restaurant.additionalIngredients').toUpperCase()}
                            </span>
                            <div>
                                {getAdditionalIngredients()?.map((each: IngredientModel) => {
                                    return (
                                        <div
                                            key={each.name}
                                            className={`${classes.flexRowSpaceBetween} ${classes.ingredientText}`}
                                        >
                                            <div className={classes.flexRowSpaceBetween}>
                                                <Checkbox
                                                    style={{ marginLeft: -10 }}
                                                    checked={getValue(each.id, 'isAdditionChecked')}
                                                    onChange={() => {
                                                        changeValue(
                                                            'isAdditionChecked',
                                                            each.id,
                                                            each.isAdditionChecked
                                                        );
                                                    }}
                                                />
                                                <span>
                                                    {each.name ?? 'No Name'}
                                                    <span
                                                        style={{
                                                            color: theme.palette.primary.main,
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        {!!each.price && '  ' + each.price + ' CHF'}
                                                    </span>
                                                </span>
                                            </div>
                                            <span>
                                                {each.amount}
                                                {each.unitOfMeasure}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                </div>
            )}
            <PopupConfirmation
                open={showConfirmationPopup}
                close={() => setShowConfirmationPopup(false)}
                title={i18n.t('common.attention')}
                description={i18n.t('warnings.tooHighPrice')}
                activeBtn="Ok"
                action={() => setShowConfirmationPopup(false)}
                hideCancel
            />
        </div>
    );
}
