import React from 'react';
import { MapObjectData } from 'constants/maps';
import { IconButton } from '@material-ui/core';

interface DishDietaryViewProps<T> {
    value: T;
    map: Map<T, MapObjectData>;
    className?: string;
}

export default function DishDietaryView<T>(props: DishDietaryViewProps<T>) {
    const value = props.map.get(props.value);
    return !!value?.icon ? (
        <IconButton className={props.className || ''} aria-label="add to favorites">
            <img src={value.icon} alt="chili-paper" height={14} />
        </IconButton>
    ) : null;
}
