import { useFormikContext } from 'formik';
import { useEffect } from 'react';

export default function SetFieldText({ field, text }: { field: string; text: string }) {
    const { values, setFieldValue } = useFormikContext();
    useEffect(() => {
        if ((values as any)[field] !== text) {
            setFieldValue(field, text);
        }
    }, [text]);
    return null;
}
