import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Form, Formik } from 'formik';
import i18n from 'i18n-js';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { DialogActions } from '@material-ui/core';
import PhotoSizeSelectActualIcon from '@material-ui/icons/PhotoSizeSelectActual';

import menuDetailsStore from 'store/menuDetailsStore';
import ImageContainer, {
    ImageContainerRefProps,
} from 'components/shared/components/ImageContainer';

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export interface SectionImageProps {
    sectionId: string;
    photo?: string;
    close: any;
}

interface SectionImageModel {
    photo: string;
}
export default function SectionImageMenuItem(props: SectionImageProps) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const childRef = useRef<ImageContainerRefProps>(null);
    const initalFormValues: SectionImageModel = {
        photo: props.photo || '',
    };

    const handleClickOpen = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setOpen(true);
        props.close();
    };

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setOpen(false);
    };

    return (
        <>
            <StyledMenuItem color="primary" onClick={handleClickOpen}>
                <PhotoSizeSelectActualIcon
                    fontSize="small"
                    color="disabled"
                    style={{ marginRight: 23 }}
                />
                <ListItemText primary={i18n.t('common.setPicture')} />
            </StyledMenuItem>
            <Dialog
                open={open}
                onClick={(e) => e.stopPropagation()}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{i18n.t('common.setPicture')}</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initalFormValues}
                        onSubmit={async (values) => {
                            const newPhoto = await childRef?.current?.uploadImage();
                            if (newPhoto) {
                                values.photo = newPhoto;
                            }
                            dispatch(
                                menuDetailsStore.actionCreators.setSectionPhoto(
                                    props.sectionId,
                                    values.photo
                                )
                            );
                            setOpen(false);
                        }}
                    >
                        {({ values, setFieldValue, submitForm, dirty, isSubmitting }) => (
                            <Form>
                                <>
                                    <ImageContainer
                                        //disabled={props.disabled}
                                        ref={childRef}
                                        photo={values.photo}
                                        onChange={(selected, fileUrl) => {
                                            setFieldValue('photoSelected', selected);
                                            setFieldValue('photo', fileUrl);
                                        }}
                                    />
                                    <br />
                                </>
                                <DialogActions>
                                    <Button type="button" color="primary" onClick={handleClose}>
                                        {i18n.t('button.cancel')}
                                    </Button>
                                    <Button
                                        onClick={submitForm}
                                        disabled={!dirty || isSubmitting}
                                        variant="contained"
                                        color="primary"
                                    >
                                        {i18n.t('button.save')}
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
}
