import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import * as yup from 'yup';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import i18n from 'i18n-js';

import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import BorderColorIcon from '@material-ui/icons/BorderColor';

import { SectionData } from 'store/menuDetailsStore/reducer';
import menuDetailsStore from 'store/menuDetailsStore';
import { DaysWithHours } from 'components/menu/MenuManagementTable/components/AddDailyMenuSectionModal';
import { getDayList } from 'helpers/datetimeHelper';
import { TimePickerModal, Input } from 'components/shared';
import { getInitialDates } from 'components/restaurantInformation/functions';
import ImageContainer, {
    ImageContainerRefProps,
} from 'components/shared/components/ImageContainer';
import { useStyles } from '../styles';

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export interface EditDailyMenuSectionProps {
    section: SectionData;
    onClose: any;
    menus: string[];
}

export default function EditDailyMenuSection(props: EditDailyMenuSectionProps) {
    const dispatch = useDispatch();
    const [showEditPopup, setShowEditPopup] = useState(false);

    function handleClose() {
        setShowEditPopup(false);
        setSelectedDays([]);
        setSelectedHours(defaultHours);
        props.onClose();
    }

    function submitForm(name: string, price: number, photo: string, availability: DaysWithHours) {
        dispatch(
            menuDetailsStore.actionCreators.editDailyMenuSection(props.section?.id, {
                name,
                price,
                availability,
                photo,
            })
        );
        setShowEditPopup(false);
        setSelectedDays([]);
        setSelectedHours(defaultHours);
        props.onClose();
    }

    const initialValues = {
        name: props.section?.name,
        price: props.section?.price ?? 0,
        photo: props.section?.photo || '',
    };

    const classes = useStyles();
    const defaultHours = { from: '00.00', to: '00.00' };
    const daysWithHours = useRef({ ...props.section?.availability! });
    const [selectedDays, setSelectedDays] = useState<string[]>([]);
    const [selectedHours, setSelectedHours] = useState(defaultHours);
    const [showTimePicker, setShowTimePicker] = useState(false);
    const [clickedElementRect, setClickedElementRect] = useState(null);
    const imageContainerRef = useRef<ImageContainerRefProps>(null);
    function handleDaySelection(day: string) {
        if (selectedDays.includes(day)) {
            const updatedDays = selectedDays.filter((x) => x !== day);
            setSelectedDays(updatedDays);
            let hours = { ...defaultHours };
            if (!!updatedDays.length) {
                const selectedDayHours = getDayList(daysWithHours.current).find(
                    (d) => d.key === updatedDays[updatedDays.length - 1]
                )?.workingHours;
                if (selectedDayHours) {
                    if (selectedDayHours.from !== 0 && selectedDayHours.to !== 0) {
                        hours = selectedDayHours;
                    }
                }
            }

            setSelectedHours(hours);
        } else {
            setSelectedDays([...selectedDays, day]);
            let hours = { ...defaultHours };
            const selectedDayHours = getDayList(daysWithHours.current).find(
                (d) => d.key === day
            )?.workingHours;
            if (selectedDayHours) {
                if (selectedDayHours.from !== 0 && selectedDayHours.to !== 0) {
                    hours = selectedDayHours;
                }
            }
            setSelectedHours(hours);
        }
    }

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Name',
                })
            )
            .test(
                'existsCheck',
                i18n.t('form.errors.alreadyTaken'),
                (value: any) => !props.menus.includes(value?.toUpperCase())
            ),
        price: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .required(
                i18n.t('form.errors.required', {
                    name: 'Price',
                })
            )
            .min(0.1),
    });

    function handleTimePicker(e: any) {
        setClickedElementRect(e?.target.getBoundingClientRect());
        setShowTimePicker(true);
    }

    /*
    useEffect(() => {
        if (selectedDays.length === 1) {
            setSelectedHours(
                daysWithHours.current[selectedDays[0].toString() as keyof DaysWithHours]
            );
        } else {
            setSelectedHours(defaultHours);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDays]);
     */

    return (
        <>
            <StyledMenuItem
                color="primary"
                onClick={(e) => {
                    e.stopPropagation();
                    setShowEditPopup(true);
                }}
            >
                <BorderColorIcon fontSize="small" color="disabled" style={{ marginRight: 23 }} />
                <ListItemText primary={i18n.t('common.edit')} />
            </StyledMenuItem>
            <Dialog
                open={showEditPopup}
                aria-labelledby="form-dialog-title"
                onClick={(e) => e.stopPropagation()}
            >
                <DialogTitle id="form-dialog-title">{i18n.t('common.editMenu')}</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            imageContainerRef?.current?.uploadImage().then((photo) => {
                                if (photo.length > 0) values.photo = photo;
                                submitForm(values.name, values.price, values.photo, {
                                    ...daysWithHours.current,
                                });
                            });
                            /* </DialogContent>/const result = await agent.Menus.AddDailyMenuSection(model); */
                        }}
                    >
                        {({ errors, submitForm, values }) => (
                            <Form>
                                <ImageContainer ref={imageContainerRef} photo={values.photo} />
                                <>
                                    <Input
                                        error={errors.name}
                                        variant="filled"
                                        fullWidth={true}
                                        name={`name`}
                                        type="text"
                                        label={i18n.t('form.name')}
                                        placeholder={i18n.t('form.name')}
                                    />
                                    <br />
                                    <Input
                                        error={errors.price}
                                        variant="filled"
                                        fullWidth={true}
                                        name={`price`}
                                        type="text"
                                        label={i18n.t('form.price')}
                                        placeholder={i18n.t('form.price')}
                                    />
                                </>
                                <div className={classes.daysContainer}>
                                    {getDayList(daysWithHours.current).map((day) => (
                                        <div
                                            key={day.name}
                                            className={clsx(classes.dayButton, {
                                                [classes.dayButtonSelected]: selectedDays.includes(
                                                    day.key
                                                ),
                                            })}
                                            onClick={() => handleDaySelection(day.key)}
                                        >
                                            {day.name}
                                        </div>
                                    ))}
                                </div>
                                <br />
                                <Input
                                    variant="filled"
                                    fullWidth={true}
                                    name={`hours`}
                                    type="text"
                                    label={i18n.t('restaurant.menuHours')}
                                    placeholder={i18n.t('restaurant.menuHours')}
                                    //disabled
                                    onClick={(e: any) => {
                                        selectedDays.length && handleTimePicker(e);
                                    }}
                                    value={selectedHours.from + ' - ' + selectedHours.to}
                                />
                                <DialogActions>
                                    <Button type="button" color="primary" onClick={handleClose}>
                                        {i18n.t('button.cancel')}
                                    </Button>
                                    <Button
                                        onClick={submitForm}
                                        variant="contained"
                                        color="primary"
                                    >
                                        {i18n.t('common.save')}
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                    <TimePickerModal
                        initialFromHour={getInitialDates(selectedHours, 'from', 'hour')}
                        initialFromMinute={getInitialDates(selectedHours, 'from', 'minute')}
                        initialToHour={getInitialDates(selectedHours, 'to', 'hour')}
                        initialToMinute={getInitialDates(selectedHours, 'to', 'minute')}
                        clickedElementRect={clickedElementRect}
                        isVisible={showTimePicker}
                        onClose={(selectedHours) => {
                            selectedDays.length &&
                                selectedDays.forEach((day) => {
                                    daysWithHours.current[day.toString() as keyof DaysWithHours] =
                                        selectedHours;
                                });
                            setShowTimePicker(false);
                        }}
                        onDateChange={(selectedHours) => {
                            setSelectedHours(selectedHours);
                        }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}
